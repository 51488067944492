import request from '@/utils/request'

// 用户列表
export function users_index(data) {
  return request({
    url: '/merchant/users/index',
    method: 'get',
    params: data
  })
}
// 余额明细
export function users_bill(data) {
  return request({
    url: '/merchant/users/bill',
    method: 'get',
    params: data
  })
}

// 创建用户
export function users_add(data) {
  return request({
    url: '/merchant/users/add',
    method: 'post',
    data: data
  })
}
// 编辑用户
export function users_edit(data) {
  return request({
    url: '/merchant/users/edit',
    method: 'post',
    data: data
  })
}
// 修改用户余额
export function users_balance(data) {
  return request({
    url: '/merchant/users/balance',
    method: 'post',
    data: data
  })
}
// 更新用户状态
export function users_able(data) {
  return request({
    url: '/merchant/users/able',
    method: 'post',
    data: data
  })
}
// 用户申请列表
export function userApply_index(data) {
  return request({
    url: '/merchant/userApply/index',
    method: 'get',
    params: data
  })
}
// 用户申请拒绝
export function userApply_rejection(data) {
  return request({
    url: '/merchant/userApply/rejection',
    method: 'post',
    data: data
  })
}
// 用户申请通过
export function userApply_approved(data) {
  return request({
    url: '/merchant/userApply/approved',
    method: 'post',
    data: data
  })
}
// 用户地址更改
export function users_changeAddress(data) {
  return request({
    url: '/merchant/users/changeAddress',
    method: 'post',
    data: data
  })
}

// 用户地址
export function users_getAddress(data) {
  return request({
    url: '/merchant/users/getAddress',
    method: 'get',
    params: data
  })
}
// 更新会员等级
export function users_level(data) {
  return request({
    url: '/merchant/users/level',
    method: 'post',
    data: data
  })
}
// 更新股东等级
export function users_bonus_level(data) {
  return request({
    url: '/merchant/users/bonusLevel',
    method: 'post',
    data: data
  })
}
// 旅游报名列表
export function userTravelRegistration_index(data) {
  return request({
    url: '/merchant/userTravelRegistration/index',
    method: 'get',
    params: data
  })
}
// 修改旅游报名状态
export function userTravelRegistration_changeState(data) {
  return request({
    url: '/merchant/userTravelRegistration/changeState',
    method: 'post',
    data: data
  })
}
// 福利礼包发放列表
export function userWelfarePackage_index(data) {
  return request({
    url: '/merchant/userWelfarePackage/index',
    method: 'get',
    params: data
  })
}
// 批量处理福利礼包
export function userWelfarePackage_batchOpt(data) {
  return request({
    url: '/merchant/userWelfarePackage/batchOpt',
    method: 'post',
    data: data
  })
}
// 鉴权
export function users_authCode(data) {
  return request({
    url: '/merchant/users/authCode',
    method: 'post',
    data: data
  })
}

//搜索用户
export function user_search(data) {
  return request({
    url: '/merchant/users/search',
    method: 'get',
    params: data
  })
}

//获取业务员
export function users_salesman(data) {
  return request({
    url: '/merchant/users/salesman',
    method: 'get',
    params: data
  })
}
//福利礼包修改备注
export function userWelfarePackage_changeRemark(data) {
  return request({
    url: '/merchant/userWelfarePackage/changeRemark',
    method: 'post',
    data: data
  })
}


//审核用户修改备注
export function userApply_changeRemark(data) {
  return request({
    url: '/merchant/userApply/changeRemark',
    method: 'post',
    data: data
  })
}