<!-- 广告管理 -->

<style scoped>
	.img{
		/* width:30px; */
		height:30px
	}
	.ellipsis-1{
		max-width: 180px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; 
		word-wrap: break-word;
		 word-break: break-all 
	}
	.w150{
		width: 150px;
	}
  .w100{
		width: 100px;
	}
</style>
<template>
  <div class="pageCentent">
    <a-tabs v-model="ajaxparams.role" @change="search" v-if="!queryData.role">
      <a-tab-pane :key="1" tab="业务沟通记录"></a-tab-pane>
      <a-tab-pane :key="2" tab="农资大市场沟通记录"></a-tab-pane>
    </a-tabs>
    <div >
      <a-form-model v-if="ajaxparams.role==1" size="small" layout="inline" @keyup.native.enter="search()">
          <a-form-model-item label="姓名">
            <a-input  placeholder="请输入客户姓名" v-model="ajaxparams['customer_name_no']" />
          </a-form-model-item>
          <a-form-model-item label="业务经理">
            <a-input  placeholder="请输入业务经理姓名" v-model="ajaxparams['manager_name_no']" />
          </a-form-model-item>
          <a-form-model-item label="沟通方式" prop="type">
          <a-select class="w100" v-model="ajaxparams['type']" placeholder="请选择" allowClear>
            <a-select-option :value="1" >电话</a-select-option>
            <a-select-option :value="2" >微信</a-select-option>
            <a-select-option :value="3" >拜访</a-select-option>
          </a-select>
        </a-form-model-item>
          <a-form-model-item label="选择日期">
            <a-date-picker v-model="ajaxparams['contact_date']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" value=null />
          </a-form-model-item>
          <a-form-model-item >
            <a-button icon="search" @click="$refs.myTable.search()" type="primary"> 搜索</a-button>
          </a-form-model-item>
        </a-form-model>
      <MyTable class="mt10" :isSelection="false" :apiUrl="apiUrl" :params="ajaxparams" ref="myTable" :columns="columns">
        <a-space slot='type' slot-scope="item,row,index">
          <span>{{row.type==1?'电话':row.type==2?'微信':'拜访'}}</span>
        </a-space>
        <a-space slot='duration' slot-scope="item,row,index">
          <span>{{returnTime(row.duration)}} </span>
        </a-space>
        <a-space slot="image_url" slot-scope="item,row,index">
            <img
              @click="showImgDialog(row.image_url)"
              v-if="row.image_url"
              class="img"
              :src="IMGHOST + '/' + row.image_url"
            />
				</a-space>
        <a-space slot='province_code' slot-scope="item,row,index">
          <span>{{find_province(item)}}</span>
        </a-space>
      </MyTable>
      <a-modal :footer="null" v-model="imgDialogVisible" title="查看">
        <img style="width:100%" :src="dialogImg" alt />
      </a-modal>
    </div>
	
  </div>
</template>
<script>
import imgUpload from "@/components/myUpload.vue"
import MyTable from "@/components/myTable.vue"
import {contactLog_index} from '@/api/manager'
import {region} from '@/api/common'

export default {
	components:{MyTable},
  data() {
    return {
		IMGHOST:window.IMGHOST,
		apiUrl:contactLog_index,
		ajaxparams:{role:1,type:undefined},
		imgDialogVisible:false,
    dialogImg:"",
    role:'',
		columns:[
			{ title: '日期', ellipsis: true,dataIndex: 'contact_date',key: 'contact_date'},
      { title: '客户名称', ellipsis: true,dataIndex: 'customer.customer_name',key: 'customer.customer_name'},
			{ title: '沟通内容', ellipsis: true,dataIndex: 'content',key: 'content'},
      { title: '客户反馈', ellipsis: true,dataIndex: 'feedback',key: 'feedback'},
      { title: '沟通结果', ellipsis: true,dataIndex: 'result',key: 'result'},
      { title: '下次沟通计划', ellipsis: true,dataIndex: 'next_plan',key: 'next_plan'},
			{ title: '沟通方式', ellipsis: true,dataIndex: 'type', key: 'type',scopedSlots: { customRender: "type" }},
			{ title: '沟通时长', ellipsis: true,dataIndex: 'duration', key: 'duration',scopedSlots: { customRender: "duration" }},
      { title: '沟通截图', ellipsis: true,dataIndex: 'image_url', key: 'image_url',scopedSlots: { customRender: "image_url" }},
      { title: '业务经理', ellipsis: true,dataIndex: 'manager.manager_name',key: 'manager.manager_name'},
      { title: '省份', ellipsis: true,dataIndex: 'customer.province_code',key: 'customer.province_code',scopedSlots: { customRender: "province_code" }},
		],
		queryData:{

    },
    region:[],
	}
  },
  created() {
    this.queryData = this.$route.query
    if(this.queryData.role){
      this.ajaxparams.role = this.queryData.role
    }
  },
  mounted(data) {
    this.region = this.$db.get('regionList')
  },
  destroyed() {  
  },
  methods: {
    showImgDialog(src) {
      this.dialogImg = this.IMGHOST + '/' +  src
      this.imgDialogVisible = true
    },
    find_province(province_code){
      const res = this.region.data.find(item => item.code == province_code)
      if(res) return res.name
      return '-'
    },
    search(){
      this.ajaxparams = {role:this.ajaxparams.role}
      this.$refs.myTable.search()
    },
    returnTime(duration){
      let m = parseInt(duration/60)
      let s = duration%60
      return m+'分'+s+'秒'
    },
    //选择日期
    changeDate(date,dateString){
      this.travelDate = dateString
    },
    showDialog(type,id){
      this.currentId = id
      this.buttonType = type
      this.dialogVisible = true
    },
    mobile(id_card){
      var mobile = id_card.substr(0, 6) + '*******' + id_card.slice(14)
      return mobile
    },
    
    approved(row){
      let postData = {
        id:this.currentId,
        state:this.buttonType === 'deal' ? 1 : -1
      }
      if(this.remark) postData.remark = this.remark
      if(this.travelDate) postData.date = this.travelDate
      userTravelRegistration_changeState(postData).then((res)=>{
        this.$message.success('操作成功');
        setTimeout(()=>{
          this.dialogVisible = false
          this.$refs.myTable.getList()
        },1500)
      })
    },
  },
}
</script>
	